<script setup lang="ts">
defineSlice({
  name: { label: 'Customers Marquee', group: 'Customers', layouts: ['VoixMarketing'] },
  preview: 'SlicesCustomersMarquee.jpg',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const logos = [
  { img: '/imgs/customer-logos/atlantis.png', width: 150 },
  { img: '/imgs/customer-logos/jewel-grande.png', width: 150 },
  { img: '/imgs/customer-logos/logo-sanctuary-white.png', width: 125 },
  { img: '/imgs/customer-logos/logo-hyatt-zilara-cap-cana.png', width: 150 },
  { img: '/imgs/customer-logos/hilton-all-inclusive-logo-white.png', width: 100 },
  { img: '/imgs/customer-logos/nintendo.png', width: 150 },
  { img: '/imgs/customer-logos/logo-hyatt.png', width: 150 },
  { img: '/imgs/customer-logos/logo-playa-hotels-and-resorts.png', width: 100 },
  { img: '/imgs/customer-logos/jewel-resorts.png', width: 150 },
  { img: '/imgs/customer-logos/logo-hyatt-ziva-cap-cana.png', width: 150 },
]
</script>

<template>
  <div class="logo-marquee-container">
    <div class="logo-marquee">
      <!-- First set of logos -->
      <div v-for="(logo, index) in logos" :key="`first-${index}`" class="logo">
        <NuxtImg loading="lazy" :src="logo.img" alt="Logo" :width="`${logo.width}px`" />
      </div>

      <!-- Duplicate the logos to create the infinite loop effect -->
      <div v-for="(logo, index) in logos" :key="`second-${index}`" class="logo">
        <NuxtImg loading="lazy" :src="logo.img" alt="Logo" :width="`${logo.width}px`" />
      </div>
    </div>

    <!-- Mask to fade out the logos -->
    <div class="mask" />
  </div>
</template>

<style scoped>
.mask {
  @apply absolute inset-0;
  /* Gradient on either side fading to black */
  background: linear-gradient(to right, rgba(10, 10, 10, 1), rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, rgba(10, 10, 10, 1));
}

.logo-marquee-container {
  @apply relative overflow-hidden w-full max-w-5xl mx-auto;
}

.logo-marquee {
  @apply flex items-center justify-start whitespace-nowrap;
  animation: scroll 35s linear infinite;
}

.logo {
  @apply w-[175px] flex justify-center items-center aspect-square px-4 flex-shrink-0 border border-neutral-800 mx-8;
}

.logo img {
  @apply object-contain saturate-0 brightness-200;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Optimize for seamless transition */
.logo-marquee {
  width: max-content; /* Ensure the width only fits the content exactly */
}
</style>
